import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, initialize, destroy } from 'redux-form';
import { useDispatch } from 'react-redux';
import { Typography, Box, FormControlLabel, Radio, Button } from '@mui/material';
import { renderRadioGroup, CustomTextInput } from '../../../../components/ReduxFormFields';
import i18n from '../../../../i18n';
import { timeParser, required, time } from '../../../validation';
import { Expander } from '../../../../components';

const MAX_ALLOWED_STEPS = 7;

const renderLabel = (label, color) => (
  <Typography sx={{ color, m: 0 }} variant="h4">
    {label}
  </Typography>
);

const NestedStepForm = ({
  isAddStep,
  isEdit,
  handleAdd,
  handleEdit,
  handleDelete,
  handleSubmit,
  daySteps = [],
  label,
  form,
  initialValues = {}
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initialize(form, initialValues));
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => () => dispatch(destroy(form)), []); // eslint-disable-line react-hooks/exhaustive-deps

  const isDisableDeleteEndChangeTime = (initialValues?.type === 'edit' && initialValues?.elementIndex === 0);
  const isDisableDelete = daySteps.length === 1;

  return (
    <>
      <Box sx={{ display: 'flex', mb: '3px' }}>
        <Typography variant="h4" sx={{ flex: 1, color: 'secondary.stepColor' }}>
          {i18n.t(label)}
        </Typography>
        {daySteps.length < MAX_ALLOWED_STEPS && !isAddStep && !isEdit && (
          <Button
            disableElevation
            disableRipple
            variant="text"
            sx={{ color: '#040D17', py: 0, px: 1, minWidth: 'auto' }}
            style={{ backgroundColor: 'transparent' }}
            onClick={() => handleAdd(true)}
          >
            <i className="fa fa-plus" />
          </Button>
        )}
        {isEdit && (
          <Button
            color="secondary"
            disabled={isDisableDeleteEndChangeTime || isDisableDelete}
            onClick={() => handleDelete(initialValues?.elementIndex)}
            disableElevation
            disableRipple
            title={i18n.t('deleteTimeBlock')}
            variant="text"
            sx={{
              borderRadius: '27px',
              width: '24px',
              height: '24px',
              color: 'secondary.stepColor',
              py: 0,
              px: 1,
              minWidth: 'auto',
              '&:disabled': {
                opacity: 0.5,
                color: 'secondary.stepColor'
              },
              '&:hover': {
                backgroundColor: '#FF2C2C',
                color: '#fff'
              }
            }}
          >
            <i className="fa fa-trash" />
          </Button>
        )}
        {(isAddStep || isEdit) && (
          <Button
            disableElevation
            disableRipple
            variant="text"
            sx={{
              borderRadius: '27px',
              width: '24px',
              height: '24px',
              marginRight: '3px',
              marginLeft: '3px',
              color: 'secondary.stepColor',
              py: 0,
              px: 1,
              minWidth: 'auto',
              '&:hover': {
                backgroundColor: '#FF2C2C',
                color: '#fff'
              }
            }}
            onClick={() => {
              handleAdd(false);
              handleEdit(false);
            }}
          >
            <i className="fa fa-close" />
          </Button>
        )}
        {(isAddStep || isEdit) && (
          <Button
            onClick={handleSubmit}
            disableElevation
            disableRipple
            variant="text"
            sx={{ color: 'primary.main', py: 0, px: 1, minWidth: 'auto' }}
            style={{ backgroundColor: 'transparent' }}
          >
            <i className="fa fa-check" />
          </Button>
        )}
      </Box>
      <Expander expanded={isAddStep || isEdit}>
        <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: 'primary.dark', px: 1, py: 2, borderRadius: '7px', my: 2 }}>
          <Box sx={{ mr: 5 }}>
            <Typography sx={{ color: 'secondary.stepSubtitleColor', fontSize: '10.5px', ml: 1.5 }} variant="body2">
              {i18n.t('tariff')}
            </Typography>
            <Field
              sx={{
                justifyContent: 'space-between',
                minWidth: 'max-content'
              }}
              row
              name="tariffOption"
              component={renderRadioGroup}
              validate={[required]}
            >
              <FormControlLabel
                sx={{ m: 0, mr: 4 }}
                value="low"
                control={(
                  <Radio
                    disableRipple
                    sx={{
                      color: 'secondary.lowTariff',
                      '&.Mui-checked': {
                        color: 'secondary.lowTariff'
                      }
                    }}
                  />
                )}
                label={renderLabel(i18n.t('low'), 'secondary.lowTariff')}
              />
              <FormControlLabel
                sx={{ m: 0, mr: 4 }}
                value="high"
                control={(
                  <Radio
                    disableRipple
                    sx={{
                      color: 'secondary.highTariff',
                      '&.Mui-checked': {
                        color: 'secondary.highTariff'
                      }
                    }}
                  />
                )}
                label={renderLabel(i18n.t('high'), 'secondary.highTariff')}
              />
            </Field>
          </Box>
          <Box>
            <Field
              name="fromTime"
              disabled={isDisableDeleteEndChangeTime}
              component={CustomTextInput}
              sx={{
                mr: 5,
                '& .Mui-disabled': {
                  opacity: 0.5
                }
              }}
              validate={[required, time]}
              parse={timeParser}
              label={i18n.t('fromDateLabel')}
              placeholder={i18n.t('selectTime')}
              className="m-input opened__time-picker"
            />
          </Box>
        </Box>
      </Expander>
    </>
  );
};

export default reduxForm({
  enableReinitialize: true
})(NestedStepForm);

NestedStepForm.propTypes = {
  isAddStep: PropTypes.bool.isRequired,
  daySteps: PropTypes.arrayOf(PropTypes.shape({
    tariff: PropTypes.string,
    from: PropTypes.string
  })),
  label: PropTypes.string.isRequired,
  handleAdd: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  isEdit: PropTypes.bool.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired
};
